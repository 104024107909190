
import { useLocalize } from 'helper';
import BaseModal from 'helper/component/basemodal';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'redux/actions/modal';


const Policy = () => {
    const dispatch = useDispatch();
    const [localize] = useLocalize();
    const onClick = (ga, action) => () => {
        window.localStorage.setItem(ga, action)
        dispatch(toggleModal({
            name: 'policy'
        }));
    };
    return (
        <BaseModal showCloseIcon={false} tag={'policy'} title={localize("txt_system")}>
            <div className="policy-content">
                <div className='title-text gunar-font'>
                    <p>{localize('txt_consent_title')}</p>
                    <p>CODM</p>
                    <p>{localize('txt_consent_event_name')}</p>
                </div>
                <div className='text__wrapper'>
                <div dangerouslySetInnerHTML={{ __html: localize('txt_consent_content')}} className="text gunar" />
                </div>
            </div>
            <div className="group">
                <button onClick={onClick('policy', 0)} className="btn btn-reject">
                    <span className="refrigerator">{localize('txt_consent_reject')}</span>
                    {/* <span className="refrigerator">Reject</span> */}
                </button>
                <button onClick={onClick('policy', 1)} className="btn btn-accept">
                    <span className="refrigerator">{localize('txt_consent_accept')}</span>
                    {/* <span className="refrigerator">Accept</span> */}
                </button>
            </div>
        </BaseModal>
    );
}
export default Policy;
import axios from "axios";
import { getRegionByHostname } from "helper";
import { toggleModal } from "./modal";

const cdn = axios.create({
	baseURL: `https://webid.cdn.garenanow.com/gstaticid/trapp/`,
	withCredentials: false,
});

export const putData = (key, data) => (dispatch) =>
	dispatch({ type: "PUT_DATA", payload: { key, data } });

export const getTranslate = () => {
	return (dispatch) => {
		const region = getRegionByHostname();
		document.documentElement.setAttribute("data-lang", region);
		const events = cdn.get(
			`${process.env.REACT_APP_LOCALIZE_NAME}.json?${Math.random() * 10000}`
		);
		const commons = cdn.get(
			`tr_common.json?${Math.floor(Math.random() * 10000)}`
		);
		const rarities = cdn.get(
			`codm_rarity.json?${Math.floor(Math.random() * 10000)}`
		);
		axios.all([commons, events, rarities]).then((resp) => {
			const json = {
				...resp[0].data[region],
				...resp[1].data[region],
				...resp[2].data[region],
			};
			dispatch(putData("localize", json));
		});
	};
};

export const getInfo = (init = false) => {
	return (dispatch) => {
		axios
			.get("api/user_info/", { area: init ? "initialize" : null })
			.then((resp) => {
				dispatch({
					type: "INITIALIZE_INFO",
					payload: {
						data: { ...resp.data, init },
					},
				});
				if (init) {
					if (
						getRegionByHostname() === "TH" &&
						!window.localStorage.getItem("policy")
					) {
						dispatch(
							toggleModal({
								name: "policy",
								state: true,
							})
						);
					}
				}
			});
	};
};

/* FEED API **/

export const postDailyToken = () => (dispatch) => {
	axios.post("/api/goat/claim/daily_token/").then((resp) => {
		dispatch({
			type: "CLAIM_DAILY_TOKEN",
			payload: {
				data: resp.data,
			},
		});
		dispatch(getInfo());
	});
};

export const postActionHistory = () => (dispatch) => {
	axios.get("api/goat/action_history/").then((resp) => {
		dispatch(putData("actionHistories", resp.data.data));
	});
};

export const postShare = (type, callback, share) => (dispatch, getState) => {
	const content = getState().main.localize?.txt_share_content;
	const hasShared = getState().main.hasShared;
	const WA_URL = "https://wa.me/?phone&text=";
	if (hasShared) {
		if (type === "copy") {
			callback(window.location.href);
		} else {
			window.location.href = WA_URL + encodeURIComponent(content + " " + share);
		}
		return;
	}
	axios.post("api/goat/claim/daily_share/").then((resp) => {
		if (type === "copy") {
			callback();

			setTimeout(() => {
				dispatch({
					type: "CLAIM_DAILY_TOKEN",
					payload: {
						data: resp.data,
					},
				});
				dispatch(getInfo());
			}, 1000);
		} else {
			window.localStorage.setItem("sharing", JSON.stringify(resp.data));
			window.location.href = WA_URL + encodeURIComponent(content + " " + share);
		}
	});
};

export const postFeed = (type, count) => (dispatch) => {
	setTimeout(() => {
		dispatch({
			type: "STOP_BUNNY_GIF",
		});
	}, 0);
	axios
		.post("/api/goat/feed/", { token_type: type, qty: count })
		.then((resp) => {
			dispatch(getInfo());
			dispatch(getMilestone());
			dispatch({
				type: "PLAY_BUNNY_GIF",
				payload: {
					data: type,
				},
			});
		});
};

export const getMilestone =
	(init = false) =>
	(dispatch) => {
		axios
			.get("api/goat/feed/", { area: init ? "initialize" : null })
			.then((resp) => {
				dispatch(putData("milestones", resp.data.data));
			});
	};

export const postMilestone = (id) => (dispatch) => {
	axios.post(`api/goat/claim/${id}/`).then((resp) => {
		dispatch({
			type: "CLAIM_MILESTONE",
			payload: {
				data: resp.data.rewards,
			},
		});
		dispatch(getMilestone());
		dispatch(getInfo());
	});
};

/* LUCKY SPIN API **/

export const getLeaderboard =
	(init = false) =>
	(dispatch) => {
		axios
			.get("api/spin/leaderboard/", { area: init ? "initialize" : null })
			.then((resp) => {
				dispatch(putData("leaderboards", resp.data));
			});
	};

export const getSpin =
	(init = false) =>
	(dispatch) => {
		axios
			.get("api/spin/", { area: init ? "initialize" : null })
			.then((resp) => {
				dispatch(putData("wheels", resp.data.wheel));
			});
	};

export const postSpin = () => (dispatch) => {
	axios.post("api/spin/").then((resp) => {
		dispatch({
			type: "PLAY_ANIMATION",
			payload: {
				// data: Math.floor(Math.random() * 12) + 1
				data: resp.data.reward,
			},
		});
		if (resp.data.reward?.is_physical) {
			dispatch(getLeaderboard());
		}
		dispatch(getInfo());
	});
};

export const postSpinHistory = () => (dispatch) => {
	axios.get("api/spin/history/").then((resp) => {
		dispatch({
			type: "OPEN_HISTORY_SPIN",
			payload: {
				data: resp.data.histories,
			},
		});
	});
};

import classNames from 'classnames';
import { useLocalize, usePersistState } from 'helper';
import React, { useState } from 'react';
import Slider from 'react-slick';
import BaseModal from 'helper/component/basemodal';
import { Table } from 'component';
import { useDispatch, useSelector } from 'react-redux';
import { postActionHistory } from 'redux/actions/main';

const NextArrow = (props) => {
	const { onClick } = props;
	const icoArrow = classNames(['btn ico-arrow btn-next']);
	return <button className={icoArrow} onClick={onClick} disabled={props.currentSlide === props.slideCount - 1} />;
}

const PrevArrow = (props) => {
	const { onClick } = props;
	const icoArrow = classNames(['btn ico-arrow btn-prev']);
	return <button className={icoArrow} onClick={onClick} disabled={props.currentSlide === 0} />;
}

const Info = () => {
    const dispatch = useDispatch();
	const [isFirstTime, setFirstTime] = usePersistState(true, 'first_time_rules');
	const [tab, setTab] = useState('tutorial');
	const [localize] = useLocalize();
	const tabClass = (active) => classNames(['btn tab gunar', active === tab && 'active']);
	const onChangeTab = (tab, firstTime) => () => {
		setTab(tab);
        if (tab === 'history') {
            dispatch(postActionHistory())
        }
		if (isFirstTime) {
			setFirstTime(false);
		}
	};
	return (
		<BaseModal tag={'info'} title={localize('txt_popup')} onExit={onChangeTab('tutorial')}>
			<div className='tab__header'>
				<button onClick={onChangeTab('tutorial')} className={tabClass('tutorial')}>
					{isFirstTime && <div className='mark' />}
					<span>{localize('txt_how_to_play')}</span>
				</button>
				<button onClick={onChangeTab('rules', true)} className={tabClass('rules')}>
					<span>{localize('txt_rules')}</span>
				</button>
				<button onClick={onChangeTab('history', true)} className={tabClass('history')}>
					<span>{localize('txt_history')}</span>
				</button>
			</div>
			<div key={tab} className={`tab__content animated fadeIn`}>
				{tab === 'rules' ? <Rules /> : tab === 'history' ? <History /> : <Carousel />}
			</div>
		</BaseModal>
	);
};

const History = () => {
	const [localize] = useLocalize();
    const histories = useSelector(state => state.main.actionHistories);
	return (
		<div className='history__container'>
			<div className='table__wrapper'>
				<Table heads={['txt_date', 'txt_action', 'txt_token']}>
					{
                        histories.map((history, index) => {
                            return (
                                <tr key={index}>
                                    <td>{history.created_at}</td>
                                    <td>{localize(`txt_action_${history?.action?.toLowerCase()}`)}</td>
                                    <td>{history.spend}</td>
                                </tr>
                            )
                        })
                    }
				</Table>
			</div>
		</div>
	);
}

const Rules = () =>{
	const [localize] = useLocalize();
	return (
		<div className='rules__container'>
			<div className='rules__wrapper'>
				<div className='text gunar' dangerouslySetInnerHTML={{ __html: localize('txt_modal_rules_content') }}></div>
			</div>
		</div>
	);
}

const Carousel = () => {
	const [localize] = useLocalize();
	const [index, setIndex] = useState(0);
	const settings = {
		dots: true,
		arrows: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: false,
		lazyLoad: 'progressive',
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		customPaging: (i) => {
			const dotsClass = classNames(['slick-dot', i === index ? 'active' : 'inactive']);
			return <div className={dotsClass} />;
		},
		beforeChange: (prev, next) => {
			setIndex(next);
		},
	};
	return (
		<div className='carousel__container'>
			<Slider {...settings}>
				{Array.isArray(localize('tutorials')) && localize('tutorials').map((data) => {
					return (
						<div key={data?.id} className='sample-content'>
							<img alt='' src={data?.image} />
						</div>
					);
				})}
			</Slider>
		</div>
	);
}

export default Info;

const initialState = {
    msg: '',
    closeIcon: true,
    toast: false,
    type: 'error',
    name: {},
};

const modal = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'TOGGLE_MODAL':
            return {
                ...state,
                name: {
                    ...state.name,
                    [payload.name]: payload.state,
                },
            };
        case 'TOGGLE_TOAST':
            return {
                ...state,
                msg: payload.msg,
                toast: payload.state,
                closeIcon: payload.closeIcon,
                type: payload.type,
            };
        case 'INITIALIZE_INFO':
            return {
                ...state,
                name: {
                    ...state.name,
                    lucky: payload.data.init ? payload.data.init : state.name.lucky
                }
            }
        case 'PLAY_BUNNY_GIF':
            return {
                ...state,
                name: {
                    ...state.name,
                    confirm: false,
                },
            };
        case 'GIVEN_KIT':
            return {
                ...state,
                name: {
                    ...state.name,
                    confirm: true,
                },
            };
        case 'OPEN_HISTORY_SPIN':
            return {
                ...state,
                name: {
                    ...state.name,
                    history: true,
                },
            };

        case 'STOP_ANIMATION':
        case 'CLAIM_MILESTONE':
        case 'CLAIM_TOKEN_SHARING':
        case 'CLAIM_DAILY_TOKEN':

            return {
                ...state,
                name: {
                    ...state.name,
                    reward: true,
                },
            };
        default:
            return state;
    }
};

export default modal;

export { default as usePersistState } from './hooks/usePersistState';
export * from './hooks/useCSSVariable';
export { default as useLocalize } from './hooks/useLocalize';

export * from './game';
export * from './audio';
export * from './const';

export const wrapPromise = (promise) => {
    let status = "pending";
    let response;

    const suspender = promise.then(
        (res) => {
            status = "success";
            response = res;
        },
        (err) => {
            status = "error";
            response = err;
        }
    );

    const read = () => {
        switch (status) {
            case "pending":
                throw suspender;
            case "error":
                throw response;
            default:
                return response;
        }
    };

    return { read };
}

export default wrapPromise;



export const getRegionByHostname = () => {
    const domain = window.location.hostname;
    let region = "ID";
    if (domain.includes(".co.id")) region = "ID";
    if (domain.includes(".com")) region = "SM";
    if (domain.includes(".tw")) region = "TW";
    if (domain.includes(".in.th")) region = "TH";
    if (domain.includes(".ph")) region = "PH";
    return region;
}

export const promiseLazyImages = (listImages = ["https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/ec0820b4-f382-485e-8153-7ac922779777.jpg",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/17a83a29-7ff1-4391-8008-0863446538c1.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/3f050cba-5c97-4f45-b44f-09ae20ff456d.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/75b10f6b-1727-45c7-a9f0-658691b6a85f.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/fbf363bb-cbc7-4c1f-bcb0-30b9135142dc.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/f78d4795-d86b-458b-8952-db84d2b6f399.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/b2f8648d-c7a7-473b-8e0d-8b47d2f43851.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/9ab4f0f2-6d09-4b66-8e59-11654b98c58a.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/fcbe7ba7-df00-46ea-9de5-a77ddf3c5750.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/dc243999-95bb-4c8d-96e2-8a2addee59e2.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/b5464db4-bda2-40af-a61f-3bcd971d08d1.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/bf3564b5-6544-4c58-b05f-a91a12c6302b.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/4dfdd6ff-053c-440a-9133-a578b0ad3e5a.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/b16bed88-78e1-4e77-aff7-1e76621f95ee.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/ef17fcb7-ea15-4a43-bdf4-de5471e2e41e.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/dc097027-7023-42fc-991b-4ac1660c7450.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/873e878e-aa52-4d94-b063-4f407719d87e.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/694e897c-670c-4cfe-b221-9ea9c6eb1261.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/ccc89a26-ba5a-4e09-a179-bc9a5c9809ab.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/06d36d53-3dda-474b-ac20-cf73800f8e3f.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/b53ad1dd-1c75-4b20-9f1a-38f827f4d3ad.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/2216966f-92b2-458a-9e61-03aff06517c7.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/cdbe3b28-7112-4f43-9767-a3668b4a5d1e.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/946d8cac-a774-4e4f-b9ab-d18593360d46.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/c28a048a-2699-4ca7-bd75-65a2f853090a.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/d9baced3-13a4-4361-9d86-913b9fb96c96.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/df4918ad-ad9a-404e-b759-03dbe0e21a63.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/b358aa27-00c6-4f17-b955-490799679d7f.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/5a1d2a39-4ddd-4775-8db2-45fb6e38d403.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/96530f89-b8b2-4c05-9a75-c1d3e18034ff.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/e621dbe0-d60b-4349-bcb0-f7c86ba55e96.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/616832c0-0cd4-463a-81c6-8f703f70740d.gif",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/9716bff4-e2e4-43f6-9383-cf02a921fa8d.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/ddbc2ea3-ddc9-470a-b8f4-c2eb7ca07055.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/2f497548-c941-4f50-abbd-885e1efc2b69.gif",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/5b7fd71d-8639-4e53-9cd2-f2db445afde5.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/3fe41a1b-4862-4b1b-846f-a44f1e795175.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/a3f62aa9-ed7e-4652-8648-8c1ba81b5bd9.gif",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/406b8972-2b35-4a01-9c7a-bae28f3138be.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/e3cddd1e-b509-4305-8839-85f7766cc978.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/c9263c70-fa22-414a-9193-cf684fbfac00.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/9cd7bfee-9ebb-4808-85c8-9ee64d28fc97.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/c5a2e36d-a747-4cbf-b618-46c3c046724c.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/de14c813-5000-4c9b-8b86-4bc462b47fba.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/ea3f888a-388b-4437-8a7a-33da6a07f157.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/c2dbadeb-ea7e-47b0-a668-e2a8f927c310.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/5cb9b620-fef5-43ff-9ea8-edd1c034e928.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/14796850-c90f-4a3e-800c-723c573d1ebb.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/4bb6d024-1d7e-40d8-93c4-904e7a92a3a3.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/436e140e-b0d2-4f25-8bf7-b1e77740e1a1.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/d4422ebe-3653-4170-9f59-ed7bb444a8c5.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/3f171e58-d109-4e00-a9c4-afdcdaee16c2.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/457e1459-4c23-4e46-ad94-89eb3e621f8f.png",
    "https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/0ab85324-99c1-4a77-8635-7e5f5f4b5ac9.png",]) => {
    const promises = listImages.map((img) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = img;
            image.onload = function () {
                resolve(image)
            }
            image.onerror = function () {
                reject(image)
            }
        })
    });
    return Promise.all(promises)
}

export const getAllUrlParam = (url = window.location) => {
    let params = {};

    new URL(url).searchParams.forEach(function (val, key) {
        if (params[key] !== undefined) {
            if (!Array.isArray(params[key])) {
                params[key] = [params[key]];
            }
            params[key].push(val);
        } else {
            params[key] = val;
        }
    });

    return params;
};



export const setupLayout = () => {
    var resetTimer;

    // force single page application
    const setRootFontSize = () => {
        // designSafeArea = 1334px * 660px (landscape NOT include browser nav 90px)
        let designSafeWidth = 1334;
        let designSafeHeight = 660;
        let vmax = Math.max(window.innerHeight, window.innerWidth);
        let vmin = Math.min(window.innerHeight, window.innerWidth);
        let vmaxToFontSize = (vmax / designSafeWidth) * 100;
        let vminToFontSize = (vmin / designSafeHeight) * 100;
        let fontSize = Math.min(vmaxToFontSize, vminToFontSize);

        // set root font size and html viewport
        const elem = document.documentElement;
        elem.style.fontSize = `${fontSize}px`;
        elem.style.height = `${vmin}px`;
        elem.style.width = `${vmax}px`;
        elem.setAttribute('data-font', fontSize)
    }

    const resetRootFontSize = () => {
        if (resetTimer) {
            clearTimeout(resetTimer);
        }
        resetTimer = setTimeout(function () {
            setRootFontSize();
        }, 200);
    }
    setRootFontSize();
    window.addEventListener('resize', resetRootFontSize);
};

export const fetchCss = (lang, region) => {
    const head = document.head;
    const link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';

    link.href = `${window.location.origin}/api/css?lang=${lang}&region=${region}`;

    head.appendChild(link);
};

/**
 * Removing trailing param on url
 *
 */
export const removeParamUrl = () => {
    const location = window.location;
    window.history.pushState({}, document.title, location.origin + location.pathname);
};

/**
 * Get spesific param url
 *
 * @param {string} pa - Unique key of search string on url.
 */
export const getUrlParam = (pa) => {
    let url = window.location.href.replace(/#+.*$/),
        params = url.substring(url.indexOf("?") + 1, url.length).split("&"),
        param = {};

    for (let i = 0; i < params.length; i++) {
        let pos = params[i].indexOf("="),
            key = params[i].substring(0, pos),
            val = params[i].substring(pos + 1);

        param[key] = val;
    }

    return typeof param[pa] === "undefined" ? false : param[pa];
};

/**
 *
 * @param {object} err - Error response object from axios.
 */
export const err_msg = (err) => {
    if (err?.response) {
        switch (err?.response?.status) {
            case 500:
                return "Please Refresh Your Browser!";
            default:
                return err?.response?.data?.msg;
        }
    } else if (err?.request) {
        return "Please Refresh Your Browser!";
    } else {
        return "Please Refresh Your Browser!";
    }
};

/**
 *
 * @param {string} key - Unique key for storing and identifying your data in local storage.
 * @param {*} values - Value of data you want store in local storage.
 */
export const setLsObject = (key, values) => {
    window.localStorage.setItem(key, JSON.stringify(values));
};

/**
 *
 * @param {string} key - Unique key for accessing your stored data in local storage.
 * @returns {*} - Return any type of data you stored.
 */
export const getLsObject = (key) => {
    return JSON.parse(window.localStorage.getItem(key));
};

/**
 *
 * @param {object,array} data - list data that you want to preload
 * @param {string} prefix - prefix of image key from directus (e.g. img for img_bg)
 */
export const preload = (data, prefix = false) => {
    // prettier-ignore
    const allowedExtensions = ["jpg", "jpeg", "png", "mp3", "mp4", "webm", "mpg", "mpeg", "mov", "ico", "gif", "svg", "bmp", "otf", "ttf"];
    const audioExtensions = ["mp3", "mp4", "wav"];
    let mapping = prefix ? Object.fromEntries(Object.entries(data).filter(([key]) => key.includes(prefix))) : data;
    let audios = [];
    let images = [];

    for (let i in mapping) {
        if (mapping.hasOwnProperty(i)) {
            let fileExtension = mapping[i].split(".").pop();
            if (allowedExtensions.includes(fileExtension)) {
                if (audioExtensions.includes(fileExtension)) {
                    audios[i] = new Audio();
                    audios[i].src = mapping[i];
                } else {
                    images[i] = new Image();
                    images[i].src = mapping[i];
                }
            }

        }
    }
};

/**
 *
 * @param {component} lazyComponent - React component that you want to code splitting
 * @param {number} attemptsLeft - How many attemps to load the chunks if failed
 */
export const componentLoader = (lazyComponent, attemptsLeft = 3) => {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                // let us retry after 1500 ms
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        reject(error);
                        return;
                    }
                    componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                }, 1500);
            });
    });
};
